import image1 from "../../assets/images/images06.jpeg";
import image2 from "../../assets/images/images10.jpeg";
import image3 from "../../assets/images/images07.jpeg";
import image4 from "../../assets/images/images03.jpeg";
import image5 from "../../assets/images/images22.jpg";
import image6 from "../../assets/images/images20.jpg";
import image7 from "../../assets/images/images02.jpeg";
import image9 from "../../assets/images/images08.jpeg";
import image10 from "../../assets/images/images09.jpeg";
import image11 from "../../assets/images/images17.jpg";
import image12 from "../../assets/images/images01.jpeg";
import image13 from "../../assets/images/images21.jpg";
import image14 from "../../assets/images/images03.jpeg";

export default [
  image1,
  image2,
  image3,
  image4,
  image7,
  image5,
  image6,
  image11,
  image12,
  image10,
  image13,
  image14,
  image9,
];
