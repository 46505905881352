import React, { Component } from 'react';
import { render } from 'react-dom';

import styles from './Snackbar.css';

class Snackbar extends Component {

	static show = (message = "") => {
		const snackbarElement = document.createElement('div');
		snackbarElement.style.visibility = 'none';
		snackbarElement.style.maxHeight = '0';
		snackbarElement.style.maxWidth = '0';
		document.body.appendChild(snackbarElement);
		render(<Snackbar>{message}</Snackbar>, snackbarElement);
		setTimeout(() => {
			document.body.removeChild(snackbarElement);
		}, 4900)
	}

	render() {
		return (
			<div className={styles.Snackbar} >{this.props.children}</div>
		);
	}
}

export default Snackbar;