class FormValidation {
	static isEmpty = (element, emptyAllowed = false) => {
		element = element ? element : "";
		if (emptyAllowed === false) {
			if (element.trim().length === 0) {
				return ({ valid: false, message: 'Can\'t be empty' });
			}
		}
		return ({ valid: true, message: '' });
	}

	//Validate Name
	static validateName = (element, emptyAllowed = false) => {
		const name = element;
		const empty = this.isEmpty(name, emptyAllowed)
		if (!empty.valid) {
			return empty;
		}
		if (!name.match(/^[A-Za-z ]*$/)) {
			return ({ valid: false, message: 'Only characters are allowed.' });
		}
		return ({ valid: true, message: '' });
	}

	//Validate Email
	static validateEmail = (element, emptyAllowed = false) => {
		const email = element;
		const empty = this.isEmpty(email, emptyAllowed);
		if (!empty.valid) {
			return empty;
		}
		if (!(email.match(/^[A-Za-z0-9\._\-]*[@][A-Za-z]{2,20}[\.][A-Za-z]{2,10}$/) || (email.length === 0 && emptyAllowed === true))) {
			return ({ valid: false, message: 'Enter a valid email address.' });
		}
		return ({ valid: true, message: '' });
	}

	//Validate Mobile Number
	static validateContact = (element, emptyAllowed = false) => {
		const contact = element.trim();
		const empty = this.isEmpty(contact, emptyAllowed);
		if (!empty.valid) {
			return empty;
		}
		if (!(contact.match(/^[0-9]{10}$/) || (contact.length === 0 && emptyAllowed === true))) {
			return ({ valid: false, message: 'Enter a valid mobile number.' });
		}
		return ({ valid: true, message: '' });
	}


	//Validate Password
	static validatePassword = (element, emptyAllowed = false) => {
		const maxLength = 6;
		const pswd = element;
		const empty = this.isEmpty(pswd, emptyAllowed)
		if (!empty.valid) {
			return empty;
		}
		if (pswd.length < maxLength) {
			return ({ valid: false, message: 'Password must be at least ' + maxLength + ' characters long.' });
		}
		return ({ valid: true, message: '' });
	}

	// //Validate Confirm Password
	// const vConfirmPassword(element, vpswd) {
	//     var cpswd, pswd;
	//     cpswd = element.value;
	//     pswd = $(vpswd).val();
	//     if (cpswd != pswd || isEmpty(vpswd)) {
	//         vMessage('Passwords do not match !', vpswd, 'red');
	//         return !1
	//     }
	//     vMessage('', vpswd, 'green');
	//     return !0
	// }

	static validateForm = (element, condition = true, emsg = "", emptyAllowed = false) => {
		var elVal = element;
		var rv;
		const empty = this.isEmpty(elVal, emptyAllowed)
		if (!empty.valid) {
			return empty;
		}
		if (!condition) {
			return ({ valid: false, message: emsg });
		}
		return ({ valid: true, message: '' });

	}

}
export default FormValidation;