import React from "react";
import ActionButton from "../ActionButton/ActionButton";

import styles from "./InputText.css";

const inputText = (props) => {
  const elementConfig = props.elementConfig ? { ...props.elementConfig } : {};
  const action = props.action ? { ...props.action } : {};
  let input = null;
  let inputStyle = [styles.Input, props.style];
  let inputLabel = null;
  let inputError = null;
  let actionButton = null;
  let requiredMarker = null;
  if (props.error) {
    inputError = <span className={styles.Error}>{props.error}</span>;
  }
  if (props.validation) {
    if (props.validation.required) {
      requiredMarker = (
        <span title="Mandatory" className={styles.Required}>
          *
        </span>
      );
    }
  }
  inputLabel = (
    <label className={styles.InputLabel}>
      {props.label} {requiredMarker}
    </label>
  );

  if (action.button) {
    inputStyle = inputStyle.concat(styles.WithButton);
    actionButton = ActionButton(action);
  }

  let stylesClasses = props.style
    ? [...props.style, styles.InputBox]
    : [styles.InputBox];

  stylesClasses = props.error
    ? [...stylesClasses, styles.Error]
    : [...stylesClasses];

  input = (
    <div className={stylesClasses.join(" ")}>
      {inputLabel}
      {inputError}
      <input
        {...props.elementConfig}
        value={props.value}
        className={inputStyle.join(" ")}
        type={elementConfig.type ? elementConfig.type : "text"}
        readOnly={elementConfig.readOnly}
      />
      {actionButton}
    </div>
  );

  return input;
};

export default inputText;
