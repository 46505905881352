import React from "react";

import styles from "./Backdrop.css";

const backdrop = (props) =>
  props.show ? (
    <div
      {...props}
      className={[styles.Backdrop, props.className].join(" ")}
      onClick={props.onClick}
    >
      {props.onClick ? <span>Tap To Close</span> : null}
    </div>
  ) : null;

export default backdrop;
