import React, { Fragment } from "react";

import styles from "./HR.css";

const hr = (props) => {
  let hr = null;

  if (props.children) {
    var color = props.color ? props.color : "var(--gray)";

    hr = (
      <Fragment>
        <div
          style={{ borderBottom: "1px solid " + color }}
          className={styles.HRStart}
        ></div>
        <div
          {...props}
          style={{ ...props.style, color: color }}
          className={[styles.HRSegment, props.className].join(" ")}
        >
          {props.children}
        </div>
        <div
          style={{ borderBottom: "1px solid " + color }}
          className={styles.HREnd}
        ></div>
      </Fragment>
    );
  } else {
    hr = (
      <div
        style={{ borderBottom: "1px solid " + color }}
        className={styles.HREnd}
      ></div>
    );
  }

  return <div className={styles.HR}>{hr}</div>;
};

export default hr;
