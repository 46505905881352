import React from "react";

import styles from "./Button.css";

const button = (props) => {
  let btnType = null;
  switch (props.type) {
    case "info":
      btnType = styles.Info;
      break;
    case "success":
      btnType = styles.Success;
      break;
    case "danger":
      btnType = styles.Danger;
      break;
    default:
      btnType = styles.Primary;
  }

  return (
    <button
      {...props}
      className={[styles.Button, btnType, props.className].join(" ")}
    >
      {props.children}
    </button>
  );
};

export { button as Button };
