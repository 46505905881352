import React from "react";

import styles from "./Mosaic.css";

import images from "../MyGallary/MyGallary";

import backgroundImage from "../../assets/images/image-background01.png";

function getRandomSize(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

const mosaic = (props) => {
  let allImages = Array();

  for (var i = 0; i < images.length; i++) {
    allImages.push({ imgSrc: images[i], key: i });
  }
  return (
    <div className={styles.Mosaic}>
      {allImages.map((item) => {
        return <img key={item.key} src={item.imgSrc} />;
      })}
    </div>
  );
};

export default mosaic;
