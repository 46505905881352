import React, { Fragment } from "react";
import InputText from "./InputText/InputText";
import TextArea from "./TextArea/TextArea";
import PairInput from "./PairInput/PairInput";
import Toggle from "./Toggle/Toggle";

import styles from "./Input.css";

const input = (props) => {
  const inputProps = props.props ? { ...props.props } : {};
  switch (inputProps.type ? inputProps.type.toLowerCase() : "text") {
    case "text":
      return InputText(inputProps);
    case "textarea":
      return TextArea(inputProps);
    case "pair":
      return PairInput(inputProps);
    case "toggle":
      return Toggle(inputProps);
    default:
      return InputText(inputProps);
  }
};

export { input as Input };
