import React from 'react';
import styles from './Columns.css'


const columnFull = (props) => (
	<div {...props} className={[styles.Column, props.className].join(' ')}>{props.children}</div>
);

const column80 = (props) => (
	<div {...props} className={[styles.Column, styles.Column80, props.className].join(' ')}>{props.children}</div>
);

const column75 = (props) => (
	<div {...props} className={[styles.Column, styles.Column75, props.className].join(' ')}>{props.children}</div>
);

const column70 = (props) => (
	<div {...props} className={[styles.Column, styles.Column70, props.className].join(' ')}>{props.children}</div>
);

const column60 = (props) => (
	<div {...props} className={[styles.Column, styles.Column60, props.className].join(' ')}>{props.children}</div>
);

const column50 = (props) => (
	<div {...props} className={[styles.Column, styles.Column50, props.className].join(' ')}>{props.children}</div>
);

const column40 = (props) => (
	<div {...props} className={[styles.Column, styles.Column40, props.className].join(' ')}>{props.children}</div>
);

const column33 = (props) => (
	<div {...props} className={[styles.Column, styles.Column33, props.className].join(' ')}>{props.children}</div>
);

const column30 = (props) => (
	<div {...props} className={[styles.Column, styles.Column30, props.className].join(' ')}>{props.children}</div>
);

const column25 = (props) => (
	<div {...props} className={[styles.Column, styles.Column25, props.className].join(' ')}>{props.children}</div>
);

const column20 = (props) => (
	<div {...props} className={[styles.Column, styles.Column20, props.className].join(' ')}>{props.children}</div>
);


export {
	columnFull as ColumnFull,
	column80 as Column80,
	column75 as Column75,
	column70 as Column70,
	column60 as Column60,
	column50 as Column50,
	column40 as Column40,
	column33 as Column33,
	column30 as Column30,
	column25 as Column25,
	column20 as Column20,
};


