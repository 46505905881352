import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Input } from "../Input/Input";

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      text: this.props.value ? this.props.value : "",
    };
  }

  onTextChange = (e) => {
    const suggestions = this.props.suggestions ? this.props.suggestions : [];
    const value = e.target.value;
    this.setState({ text: value, suggestions: suggestions });
  };

  renderSuggestions = () => {
    const suggestions = this.props.suggestions ? this.props.suggestions : [];
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <datalist id={this.props.list}>
        {suggestions.map((item) => (
          <option key={item.key} value={item.item}>
            {item.key}
          </option>
        ))}
      </datalist>
    );
  };

  // Event fired when the user presses a key down
  render() {
    if (this.props.styled) {
      return (
        <Fragment>
          <Input
            label={this.props.label}
            value={this.state.text}
            title={this.props.title}
            placeholder={this.props.placeholder}
            list={this.props.list}
            onChange={
              this.props.onTextChange
                ? this.props.onTextChange
                : this.onTextChange
            }
            onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : null}
            focused={this.props.focused}
          />
          {this.renderSuggestions()}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <input
            type="text"
            value={this.state.text}
            title={this.props.title}
            placeholder={this.props.placeholder}
            list={this.props.list}
            onChange={
              this.props.onTextChange
                ? this.props.onTextChange
                : this.onTextChange
            }
            onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : null}
            focused={this.props.focused}
          />
          {this.renderSuggestions()}
        </Fragment>
      );
    }
  }
}

export default Autocomplete;
