import React from "react";

import styles from "./ActionButton.css";
import { Icons } from "../../UI";

const actionButton = (props) => {
  let button = null;
  if (props.button) {
    let actionType = styles.ActionButton;
    switch (props.type) {
      case "danger":
        actionType = styles.DangerButton;
        break;
      case "warning":
        actionType = styles.WarningButton;
        break;
      case "info":
        actionType = styles.InfoButton;
        break;
      default:
        actionType = styles.ActionButton;
    }
    button = (
      <button className={actionType} onClick={props.onClick}>
        <i
          className={[Icons[props.button[0]], Icons[props.button[1]]].join(" ")}
        ></i>
      </button>
    );
  }
  return button;
};

export default actionButton;
