import React from "react";

import styles from "./Toggle.css";

const toggleButton = (props) => {
  let input = null;
  let toggleLabel = null;
  let toggleError = null;
  if (props.label) {
    toggleLabel = <label className={styles.ToggleLabel}>{props.label} :</label>;
  }
  if (props.error) {
    toggleError = <span className={styles.Error}>{props.error}</span>;
  }

  let stylesClasses = props.styles
    ? [...props.styles, styles.InputBox]
    : [styles.InputBox];
  stylesClasses = props.error
    ? [...stylesClasses, styles.Error]
    : [...stylesClasses];
  input = (
    <div className={stylesClasses.join(" ")}>
      {toggleLabel}
      {toggleError}
      <label className={styles.Toggle}>
        <input {...props} type="checkbox" onClick={props.onClick} />
        <span className={styles.Slider}></span>
      </label>
    </div>
  );

  return input;
};

export default toggleButton;
