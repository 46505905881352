import React, { Component } from "react";

import {
  ColumnFull,
  HR,
  Column25,
  Column50,
  Column33,
  Input,
  Button,
  Icons,
  PromptBox,
} from "../../UI/UI";

import styles from "./Layout.css";
import BannerImage from "../../assets/images/images05.jpg";
import Mosaic from "../Mosaic/Mosaic";

import weddingDecoreImage from "../../assets/images/images20.jpg";
import cateringImage from "../../assets/images/images02.jpeg";
import professionalPhotographyImage from "../../assets/images/images14.jpg";
import backgroundImage1 from "../../assets/images/image-background03.png";
import backgroundImage2 from "../../assets/images/image-background02.png";
import logo from "../../assets/images/logo/Glitter-events-logo-1-Liner-White-512x512-With-tag-line--PNG.png";

class Layout extends Component {
  state = {
    services: [
      {
        key: "serv001",
        heading: "Wedding Décor",
        img: weddingDecoreImage,
        content:
          " We create and coordinate themes Floral Décor and Color Schemes Stage and Mandap design and decoration Create ambient and mood Lighting State of the Art sound, AV and Seating arrangements etc..",
      },
      {
        key: "serv002",
        heading: "Catering",
        img: cateringImage,
        content:
          "You want to organize any type of Function. No problem We handle it, we set your Function well Organized. We serve Delicious Cuisine under your Budget.",
      },
      {
        key: "serv003",
        heading: "Artist Management",
        img: cateringImage,
        content: "",
      },
      {
        key: "serv004",
        heading: "Photography",
        img: professionalPhotographyImage,
        content:
          "We capture seamless wedding moments which are sure to provide an unmatched level of quality services. We shoots the various frames of brides and grooms along with their family and friends during their wedding festivities.",
      },
    ],
    serviceList: [
      "Wedding Event Planning",
      "Corporate Event Plannning",
      "Photography & Videography",
      "Gold & Silver Jewellary",
      "Artist Management",
      "Rockband",
      "Celebrity Appearence",
    ],
    formData: {
      userName: {
        type: "input",
        value: "",
        label: "Your Name",
        elementConfig: {
          placeholder: "Your Name",
          error: "",
          onChange: (event) => {
            this.inputChangeHandler(event, "userName");
          },
          validation: {
            required: true,
          },
        },
        valid: false,
      },
      contact: {
        type: "input",
        value: "",
        label: "Contact No.",
        elementConfig: {
          placeholder: "Contact No.",
          minLength: "10",
          maxLength: "10",
          error: "",
          onChange: (event) => {
            this.inputChangeHandler(event, "contact");
          },
          validation: {
            required: true,
          },
        },
        valid: false,
      },
      message: {
        type: "textarea",
        value: "",
        label: "Your Message",
        elementConfig: {
          placeholder: "Your Message",
          error: "",
          onChange: (event) => {
            this.inputChangeHandler(event, "message");
          },
          validation: {
            required: true,
          },
        },
        valid: false,
      },
    },
  };

  renderInputForm = () => {
    const formData = { ...this.state.formData };
    const formElements = Object.keys(formData).map((item) => {
      return <Input key={item} props={{ ...formData[item] }} />;
    });
    return formElements;
  };

  inputChangeHandler = (e, inputElement) => {
    let formData = { ...this.state.formData };
    let element = { ...formData[inputElement] };
    const id = e.target.value;
    element.value = id;
    formData[inputElement] = element;
    this.setState({ formData: formData });
  };

  submitData = (e) => {
    e.preventDefault();
    const formData = { ...this.state.formData };
    const values = {};
    Object.keys(formData).map((item) => {
      values[item] = formData[item].value;
      return true;
    });
    console.log(values);
    const props = {
      prompt: {
        message:
          "We are glad to have you, one of our exicutives will contact you soon.",
      },
    };
    PromptBox.show(props);
  };

  render() {
    const bannerStyle = { backgroundImage: "url(" + BannerImage + ")" };
    const hrStyle = { fontSize: "32px", fontWeight: "bold" };
    return (
      <div className={styles.Layout}>
        <div className={styles.Body}>
          <ColumnFull
            className={[styles.Banner, styles.WithBackground].join(" ")}
            style={bannerStyle}
          >
            <ColumnFull className={styles.Header}>
              <img
                className={styles.HomeLogo}
                src={logo}
                alt="Glitter Events Logo"
              />
            </ColumnFull>
          </ColumnFull>
          <ColumnFull className={styles.Services}>
            <ColumnFull>
              <HR color="var(--primary)" style={hrStyle}>
                What We Do
              </HR>
            </ColumnFull>
            <ColumnFull>
              {this.state.services.map((item) => {
                const imageStyle = { backgroundImage: "url(" + item.img + ")" };
                return (
                  <Column25 key={item.key} className={styles.ServiceColumn}>
                    <p className={styles.ServiceHeading}>{item.heading}</p>
                    <div
                      className={styles.ServiceImage}
                      style={imageStyle}
                    ></div>
                    <p className={styles.ServiceContent}>{item.content}</p>
                  </Column25>
                );
              })}
            </ColumnFull>
          </ColumnFull>
          <ColumnFull className={styles.Gallery}>
            <HR color="var(--primary)" style={hrStyle}>
              Gallery
            </HR>
            <ColumnFull
              style={{
                backgroundImage: "url(" + backgroundImage2 + ")",
                backgroundRepeat: "repeat",
              }}
            >
              <Mosaic />
            </ColumnFull>
          </ColumnFull>
          <ColumnFull
            className={styles.ContactUs}
            style={{
              backgroundImage: "url(" + backgroundImage1 + ")",
            }}
          >
            <Column25 className={styles.Contact}>
              <HR color="#fff">Contact Us</HR>
              <span>
                Welcome to our Website. We are glad to have you around. If you
                need to contact us, you can use the details below.
              </span>
              <ul>
                <li>
                  Phone:
                  <br />
                  +91-9335-1106-47 | +91-9305-0654-70
                </li>
                <li>
                  Email:
                  <br />
                  glitterevents.in@gmail.com
                </li>
                <li>
                  Address:
                  <br />S 29/7, Near Normal School, Shivpur, Varanasi, U.P. –
                  221003
                </li>
              </ul>
            </Column25>
            <Column25 className={styles.GoogleMap}>
              <HR color="#fff">Find Us</HR>
              <div className={styles.Content}></div>
            </Column25>
            <Column25 className={styles.ServiceList}>
              <HR color="#fff">Services</HR>
              <ul>
                {this.state.serviceList.map((item) => {
                  return <li key={item}>{item}</li>;
                })}
              </ul>
            </Column25>
            <Column25 className={styles.ContactForm}>
              <HR color="#fff">Ask Us Anything</HR>
              <div className={styles.Content}>
                {this.renderInputForm()}
                <Button style={{ float: "right" }} onClick={this.submitData}>
                  Send{" "}
                  <i
                    className={[Icons["fas"], Icons["fa-paper-plane"]].join(
                      " "
                    )}
                  ></i>
                </Button>
              </div>
            </Column25>
          </ColumnFull>
        </div>
      </div>
    );
  }
}

export default Layout;
