import React from "react";
import ActionButton from "../ActionButton/ActionButton";
import styles from "./PairInput.css";

const deafultElement = {
  type: "text",
  value: "",
};

const pairInpput = (props) => {
  let value1 = null;
  let value2 = null;
  let element1 = deafultElement;
  let element2 = deafultElement;
  const action = props.action ? { ...props.action } : {};
  let input = null;
  let inputStyle = [styles.Input, props.style];
  let inputLabel = null;
  let inputError = null;
  let actionButton = null;
  let requiredMarker = null;
  let [ratio1, ratio2] = [1, 1];

  if (props.value) {
    value1 = props.value[0] ? props.value[0] : null;
    value2 = props.value[1] ? props.value[1] : null;
  }
  if (props.elements) {
    element1 = props.elements[0] ? props.elements[0] : {};
    element2 = props.elements[1] ? props.elements[1] : {};
  }

  if (props.ratio) {
    ratio1 = props.ratio.toString().split(":")[0]
      ? props.ratio.toString().split(":")[0]
      : 1;
    ratio2 = props.ratio.toString().split(":")[1]
      ? props.ratio.toString().split(":")[1]
      : 1;
  }

  ratio1 =
    (parseFloat(ratio1) / (parseFloat(ratio1) + parseFloat(ratio2))) * 100 +
    "%";
  ratio2 = 100 - parseFloat(ratio1) + "%";

  if (props.error) {
    inputError = <span className={styles.Error}>{props.error}</span>;
  }
  if (props.validation) {
    if (props.validation.required) {
      requiredMarker = (
        <span title="Mandatory" className={styles.Required}>
          *
        </span>
      );
    }
  }
  inputLabel = (
    <label className={styles.InputLabel}>
      {props.label} {requiredMarker}
    </label>
  );

  if (action.button) {
    inputStyle = inputStyle.concat(styles.WithButton);
    actionButton = ActionButton(action);
  }

  let stylesClasses = props.style
    ? [...props.style, styles.PairInputBox]
    : [styles.PairInputBox];

  stylesClasses = props.error
    ? [...stylesClasses, styles.Error]
    : [...stylesClasses];

  const input1 = element1
    ? returnPairElement({ ...element1, value: value1, ratio: ratio1 })
    : null;
  const input2 = element2
    ? returnPairElement({ ...element2, value: value2, ratio: ratio2 })
    : null;

  input = (
    <div className={stylesClasses.join(" ")}>
      {inputLabel}
      {inputError}
      {input1}
      {input2}
      {actionButton}
    </div>
  );

  return input;
};

const returnPairElement = (props) => {
  let stylesClasses = props.elementConfig
    ? [props.elementConfig.className]
    : [];
  const elementStyle = { ...props.style, width: props.ratio };

  switch (props.type) {
    case "input":
      stylesClasses = [...stylesClasses, styles.Input];
      return (
        <input
          {...props.elementConfig}
          className={stylesClasses.join(" ")}
          style={elementStyle}
        />
      );
    case "select":
      const options = props.options
        ? props.options.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.option}
              </option>
            );
          })
        : null;
      stylesClasses = [...stylesClasses, styles.Select];
      return (
        <select
          {...props.elementConfig}
          className={stylesClasses.join(" ")}
          style={elementStyle}
        >
          {options}
        </select>
      );
    case "button":
      stylesClasses = [...stylesClasses, styles.Button];
      return (
        <button
          {...props.elementConfig}
          className={stylesClasses.join(" ")}
          style={elementStyle}
        >
          {props.value}
        </button>
      );
    default:
      stylesClasses = [...stylesClasses, styles.Input];
      return (
        <input
          {...props.elementConfig}
          className={stylesClasses.join(" ")}
          style={elementStyle}
        />
      );
  }
};

export default pairInpput;
