import React, { Component, Fragment } from 'react';

import styles from './Modal.css';
import Backdrop from '../Backdrop/Backdrop';

class Modal extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		return (nextProps.show !== this.props.show);
	}

	// componentDidUpdate() {
	// 	console.log('[Modal] did update');
	// }
	render = () => {
		return (
			<Fragment>
				<Backdrop show={this.props.show} clicked={this.props.modalClosed} />
				<div
					style={{
						transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
						opacity: this.props.show ? '1' : '0',
					}}
					className={styles.Modal}>
					{this.props.children}
				</div>
			</Fragment>
		);
	}

}

export default Modal;